























































































import { Funcionario } from "@/core/models/geral";
import { PageBase } from "@/core/models/shared";
import { FuncionarioService } from "@/core/services/geral";
import { AlertExcludeQuestion, AlertSimpleErr } from "@/core/services/shared/AlertService";
import { Component, Prop, Watch} from "vue-property-decorator";

@Component
export default class ListaFuncionario extends PageBase {
    search: any = '';
    loading: boolean = false;
    dialogCadastro: boolean = false;
    totalLista: number = 0;
    lista: any[] = [];
    options: any = {
        itemsPerPage: 15
    };
    headers: any[] = [
        { text: '',value:'actions' ,sortable: false },
        { text: 'Nome', value: 'nome' },
        { text: 'Telefone 1', value: 'telefone1' },
        { text: 'Telefone 2', value: 'telefone2' },
        { text: 'E-mail', value: 'email'},
        { text: 'Função', value: 'funcao.nome'},
        { text: 'Ativo', value: 'ativo', type: 'boolean' },
    ];

    item = new Funcionario();
    service = new FuncionarioService();

    dialogExportarExcel: boolean = false;
    excelOpcao: any = 'listagem'

    @Watch('options', { deep: true })
    Atualizar(){
        const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
        this.loading = true;
        this.service.Listar(page, itemsPerPage, sortBy, sortDesc, this.search, this.headers, undefined, "Funcao").then(
            res => {
                this.lista = res.data.items;
                this.totalLista = res.data.count;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => (this.loading = false));
    }

    AbrirDialogCadastro(item?: Funcionario){
        if(item){
            this.service.ObterPorId(item.id, "Usuario").then(
                res=>{
                    this.item = res.data;
                    this.item.dataAdmissao = this.item.dataAdmissao ? this.item.dataAdmissao.toDateYYYYMMDD() : '';
                    this.item.dataExamePeriodico = this.item.dataExamePeriodico ? this.item.dataExamePeriodico.toDateYYYYMMDD() : '';
                    this.item.dataNascimento = this.item.dataNascimento ? this.item.dataNascimento.toDateYYYYMMDD() : '';
                    this.dialogCadastro = true;
                },
                err => AlertSimpleErr("Aviso!", err)
            )
        }
        else{
            this.item = new Funcionario();
            this.item.empresaId = this.app.empresaId;
            this.dialogCadastro = true;
        }
    }

    FichaFuncionario(funcionarioId: number){
        let routeLink = this.$router.resolve({ 
            name: 'fichaFuncionario', 
            params: { 
                id: funcionarioId.toString() 
            } 
        });
        window.open(routeLink.href, '_blank');
    }

    Excluir(item: Funcionario){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                await context.service.Excluir(item.id).then(
                    res => {
                        if(res.status == 200){
                            return resolve(res.data);
                        }
                    },
                    err => {
                        AlertSimpleErr("Aviso", err);
                    }
                ).finally(() => {
                    context.Atualizar();
                })
            });
        }
        AlertExcludeQuestion(excluir);
    }

    GerarExcel(opcao: string){
        if(opcao == "listagem"){
            this.ExportarExcel('tabela');
        }
        else{
            this.service.ExportarExcel().then(
                res => {
                    this.JsonToCSV(res.data, "Funcionarios", true);
                },
                err => AlertSimpleErr("Aviso!", err)
            )
        }
        this.dialogExportarExcel = false;
    }
}
