var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('master-page',{attrs:{"icone":"mdi-account-supervisor","titulo":"Funcionário","subTitulo":"Lista de funcionários para uso no sistema."}},[_c('v-row',{staticClass:"toolbar",attrs:{"align":"center"}},[_c('v-text-field',{staticClass:"mx-2",attrs:{"label":"Pesquisa rápida","clearable":"","single-linehide-details":""},on:{"change":function($event){return _vm.Atualizar()}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"fab":"","small":"","depressed":"","color":"primary"},on:{"click":function($event){_vm.dialogExportarExcel = true}}},on),[_c('v-icon',[_vm._v("mdi-file-excel-outline")])],1)]}}])},[_c('span',[_vm._v("Exportar (Excel)")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"fab":"","small":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.Atualizar()}}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Atualizar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"fab":"","small":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.AbrirDialogCadastro()}}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Novo")])])],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"id":"tabela","headers":_vm.headers,"items":_vm.lista,"options":_vm.options,"server-items-length":_vm.totalLista,"loading":_vm.loading,"footer-props":{ showFirstLastPage: true}},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"small":""},on:{"click":function($event){return _vm.AbrirDialogCadastro(item)}}},on),[_vm._v("mdi-pencil")])]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"small":""},on:{"click":function($event){return _vm.FichaFuncionario(item.id)}}},on),[_vm._v("mdi-eye")])]}}],null,true)},[_c('span',[_vm._v("Ficha")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":""},on:{"click":function($event){return _vm.Excluir(item)}}},on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Excluir")])])]}},{key:"item.clientes",fn:function(ref){
var item = ref.item;
return _vm._l((item.clientes),function(empresa){return _c('v-chip',{key:empresa.id,staticClass:"ma-1",attrs:{"color":"primary"}},[_vm._v(_vm._s(empresa.cliente.nome))])})}},{key:"item.representantes",fn:function(ref){
var item = ref.item;
return _vm._l((item.representantes),function(representante){return _c('v-chip',{key:representante.id,staticClass:"ma-1",attrs:{"color":"primary"}},[_vm._v(_vm._s(representante.representante.nome))])})}},{key:"item.ativo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.ativo.toSimNao())+" ")]}}],null,true)})],1)],1),_c('cadastro-funcionario',{attrs:{"item":_vm.item},on:{"fechou":function($event){_vm.dialogCadastro = false},"salvou":function($event){return _vm.Atualizar()}},model:{value:(_vm.dialogCadastro),callback:function ($$v) {_vm.dialogCadastro=$$v},expression:"dialogCadastro"}}),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialogExportarExcel),callback:function ($$v) {_vm.dialogExportarExcel=$$v},expression:"dialogExportarExcel"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Escolha o Layout para Gerar Excel")])]),_c('v-card-text',[_c('v-container',[_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.excelOpcao),callback:function ($$v) {_vm.excelOpcao=$$v},expression:"excelOpcao"}},[_c('v-radio',{attrs:{"label":"Listagem","value":"listagem"}}),_c('v-radio',{attrs:{"label":"Bruto","value":"bruto"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialogExportarExcel = false}}},[_vm._v("Fechar")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.GerarExcel(_vm.excelOpcao)}}},[_vm._v("Visualizar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }